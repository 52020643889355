.order-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.MuiTable-root tr td img {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  object-fit: cover !important;
}
.MuiTable-root tr td .icon-edit, .MuiTable-root tr td .editand-remove {
  background-color: transparent;
  border: none;
  margin-left: 5px;
}
.MuiTable-root tr td .email-area, .MuiTable-root tr td {
  color: #231916;
}
.MuiTable-root tr td .icon-edit img, .MuiTable-root tr td .editand-remove img {
  width: 18px !important;
  height: 18px !important;
}

.MuiTable-root tr td .editand-remove {
  margin: 0 5px;
}
.main-panel>.content h1 {
  color: #17181A;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 83.333% */
  text-transform: capitalize;
  margin-top: -66px;
  margin-bottom: 25px;
}
.order-box {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.order-box h3 {
  margin-top: 0;
  color: black;
}

.chart-container {
  flex-basis: 100%;
  /* width: 49%; */
  /* max-width: 49%; */
  margin: 30px auto;
  background-color: white;
  padding: 3em;
  border-radius: 15px;
  display: block !important;
}


.timeline-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 10px;
  /* margin-top: 20px; */
}

.table-container {
  margin-top: 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 10px;
  border: 1px solid #ccc;
}

.table-container th {
  background-color: #f2f2f2;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pie-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding-top: 35px;
  padding: 0px 35px;
  width: 29%;
  height: 480px;
  flex-grow: 1;
}

.bar-chat-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  margin: 30px auto;
  /* width: 49%; */
  flex-basis: 100%;
}

.top-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.top-box {
  background-color: #fff;
  border-radius: 15px;
  padding: 2rem 3rem;
  width: calc(24% - 0px);
}

.heading {
  color: black;
  font-size: 24px;
  font-weight: 600 !important;
}
.heading2 {
  color: black;
  font-size: 20px;
  font-weight: 600 !important;
  margin-bottom: 0;
}

.box-h3 {
  color: black;
  font-size: 16px;
  font-weight: 600 !important;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.box-counter {
  text-align: center;
  font-size: 22px;
  padding-top: 10px;
}

.box-p {
  font-size: 10px;
  text-align: center;
}

.sales {
  color: rgb(184, 68, 68) !important;
  font-weight: 600;
}

.filter-buttons {
  display: flex;
  justify-content: center;
}

.filter-buttons .filter-button,
.timeline-buttons .filter-button {
  /* Styles for the inactive filter buttons */
  margin: 5px 5px !important;
  background-color: white !important;
  border-width: 1px !important;
  border-radius: 15px !important;
  padding: 6px 17px !important;
  font-size: 11px !important;
}

.timeline-buttons .filter-button.active,
.filter-buttons .filter-button.active {
  /* Styles for the active filter button */
  color: #fff !important;
  background-color: black !important;
}

.charts {
  display: flex;
  grid-gap: 16px;
  grid-gap: 16px;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.spending {
  background-color: white;
  margin-top: 20px;
  padding: 50px;
  border-radius: 15px;
}

.spending h3 {
  color: black;
  font-weight: 600;
}

.table-container th,
.table-container td {
  padding: 10px;
  color: black;
  background-color: white;
}

.sales-icon {
  color: black;
  text-align: center;
}

.new-customer-box {
  background-color: white;
  padding: 50px;
  border-radius: 15px;
  width: 100%;
  height: 480px;
  flex-grow: 1;
}

.new-customer-box h3 {
  color: black;
  font-weight: 600;
}

.radar-chart-container {
  max-width: 400px;
  /* Adjust the maximum width as per your preference */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-bar {
  display: flex;
  justify-content: space-between;
}

.bottom-box {
  display: flex;
  gap: 16px;
  grid-gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}

.hot-product {
  padding: 50px;
  background-color: white;
  width: 630px;
  border-radius: 15px;
}

.inner-section {
  display: flex;
  justify-content: space-between;
}

.hot-product h3 {
  color: black;
  font-weight: 600;
}

.hot-product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1rem;
}
.hot-products {
  margin-top:  2rem;
}

.hot-products > h3{
  margin-bottom:  0;
}

.hot-product {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
  max-width: calc(25% - 20px);
  /* Half of the available width with gap */
}

.ranking {
  background-color: #ffc107;
  padding: 5px 10px;
  color: #1E1E29;
  border-radius: 50%;
  font-weight: bold;
  position: absolute;
  top: 15px;
  left: 15px;
}

.product-image {
  max-width: 80%;
  max-height: 200px;
  margin-bottom: 10px;
  margin: auto;
}

.product-details {
  font-size: 12px;
}

.chart-top .order-checkbox .MuiButtonBase-root  {
  color: #1976d2;
}

.chart-top .earnings-checkbox .MuiButtonBase-root ,
.chart-top .earnings-checkbox .MuiButtonBase-root .Mui-checked, 
.chart-top .earnings-checkbox .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ff851b;
}

.chart-top .customers-checkbox .MuiButtonBase-root ,
.chart-top .customers-checkbox .MuiButtonBase-root .Mui-checked, 
.chart-top .customers-checkbox .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ff1493;
}

.add-media-modal .modal-content {
  background: #fff !important;
  background: linear-gradient(0deg, #fff 0%, #fff 100%) !important;
  background-size: 200% 200%;
  -webkit-animation: Animation 15s ease infinite;
  padding: 0 !important;
}
.add-media-modal .modal-content .title-area {
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background: var(--Primary, #231916);
  padding: 16px 0;
}

.add-media-modal .modal-content .title-area h2 {
  color: #FFF;
  font-family: "Poppins";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 100% */
  text-transform: capitalize;
}

.add-media-modal .modal-content .form {
  padding: 20px 40px;
}

.add-media-modal .modal-content .form .form-group .img-upload-area,
.add-voting-page .form .form-group .img-upload-area{
  display: block;
  position: relative;
}

.add-media-modal .modal-content .form .form-group .img-upload-area .img-box,
.add-voting-page .form .form-group .img-upload-area .img-box{
  width: 100%;
  background-color: #D9D9D9;
  border: none;
  border-radius: 8px;
  margin-right: 0;
  height: 230px;
  position: relative;
}

.add-media-modal .modal-content .form .form-group .img-upload-area .text-box,
.add-voting-page .form .form-group .img-upload-area .text-box{
  position: absolute;
    top: 45%;
    left: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.add-media-modal .modal-content .form .form-group .img-upload-area .text-box p,
.add-voting-page .form .form-group .img-upload-area .text-box p{
  color: var(--Primary, #231916) !important;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  font-size: 14px;
}
.add-media-modal .modal-content .form .form-group .img-upload-area .text-box .input-file,
.add-voting-page .form .form-group .img-upload-area .text-box .input-file{
  text-align: center;
}

.add-media-modal .modal-content .form .form-group .img-upload-area .text-box .input-file .add-btn,
.add-voting-page .form .form-group .img-upload-area .text-box .input-file .add-btn{
  background-color: #fff !important;
  color: #222324 !important;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  border: 1px solid #fff !important;
}

.add-media-modal .modal-content .form .form-group .img-upload-area .text-box .icon,
.add-voting-page .form .form-group .img-upload-area .text-box .icon{
  display: block;
  margin-bottom: 10px;
}

.add-media-modal .modal-content .form .form-group .img-upload-area .text-box .input-file input#image,
.add-voting-page .form .form-group .img-upload-area .text-box .input-file input#image{
  position: absolute;
  top: -187px;
  height: 242px;
}
@media (max-width: 1550px) {
  .top-box {
    width: calc(23% - -11px);
  }
}

@media (max-width: 1460px) {
  .top-box {
    width: calc(23% - 0px);
    padding: 24px 15px;
  }
}

@media (max-width: 1199px) {

  .chart-container,
  .bar-chat-container {
    width: 100%;
    max-width: 100%;
  }

  .filter-buttons .filter-button,
  .timeline-buttons .filter-button {
    padding: 3px 10px !important;
    margin: 3px !important;
  }

  .bar-chat-container {
    margin-top: 0;
  }

  .bottom-box {
    flex-wrap: wrap;
  }

  .pie-chart-container,
  .new-customer-box {
    width: 48%;
  }

  .hot-product {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .order-box {
    flex-basis: 100%;
  }

  .top-box {
    width: calc(48% - 0px);
  }
}

@media (max-width: 560px) {

  .pie-chart-container,
  .new-customer-box,
  .hot-product,
  .spending,
  .chart-container,
  .bar-chat-container {
    width: 100%;
    padding: 15px;
  }

  .heading,
  h3,
  .h3 {
    font-size: 19px !important;
  }
}

@media (max-width: 460px) {
  .top-box {
    width: calc(46% - 0px);
  }

  .inner-section {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .ReactTable .rt-table .rt-thead .rt-tr .rt-th,
  .table .rt-tbody .rt-td {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .top-box {
    width: calc(100% - 0px);
  }
}

.chart-top  {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chart-top .dropdown,
.chart-top .dropdown-earnings,
.chart-top .dropdown-customers {
  padding: 10px 0;
  min-width: 159px;
}

.chart-top .dropdown .list,
.chart-top .dropdown-earnings .list,
.chart-top .dropdown-customers .list {
  padding: 0;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
  background-color: rgb(255, 255, 255);
  position: relative;
}

.chart-top .dropdown .list label,
.chart-top .dropdown-earnings .list label,
.chart-top .dropdown-customers .list label {
  width: 100%;
  padding: 0;
  margin: 0;
}
.chart-top .dropdown .list label span,
.chart-top .dropdown-earnings .list label span,
.chart-top .dropdown-customers .list label span {font-size: 13px;}

.chart-top .dropdown .list label span .MuiSvgIcon-root,
.chart-top .dropdown-earnings .list label span .MuiSvgIcon-root,
.chart-top .dropdown-customers .list label span .MuiSvgIcon-root{
  width: 15px;
  height: 15px;
}
.chart-top .dropdown .list:nth-child(2) 
.MuiButtonBase-root , 
.chart-top .dropdown .list:nth-child(2) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown .list:nth-child(2) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #2ecc40;
}
.chart-top .dropdown .list:nth-child(3) 
.MuiButtonBase-root , 
.chart-top .dropdown .list:nth-child(3) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown .list:nth-child(3) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ff4136;
}
.chart-top .dropdown .list:nth-child(4) 
.MuiButtonBase-root , 
.chart-top .dropdown .list:nth-child(4) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown .list:nth-child(4) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #b10dc9;
}

.chart-top .dropdown-earnings .list:nth-child(1) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(1) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(1) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ff851b;
}

.chart-top .dropdown-earnings .list:nth-child(2) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(2) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(2) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #39cccc;
}

.chart-top .dropdown-earnings .list:nth-child(3) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(3) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(3) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ffdc00;
}

.chart-top .dropdown-earnings .list:nth-child(4) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(4) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(4) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #f012be;
}

.chart-top .dropdown-earnings .list:nth-child(5) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(5) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(5) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #aaaaaa;
}

.chart-top .dropdown-earnings .list:nth-child(6) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(6) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(6) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #85144b;
}

.chart-top .dropdown-earnings .list:nth-child(7) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(7) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(7) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #3d9970;
}

.chart-top .dropdown-earnings .list:nth-child(8) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(8) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(8) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ff7f50;
}

.chart-top .dropdown-earnings .list:nth-child(9) 
.MuiButtonBase-root , 
.chart-top .dropdown-earnings .list:nth-child(9) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-earnings .list:nth-child(9) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #8a2be2;
}

.chart-top .dropdown-customers .list:nth-child(1) 
.MuiButtonBase-root , 
.chart-top .dropdown-customers .list:nth-child(1) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-customers .list:nth-child(1) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #228b22;
}
.chart-top .dropdown-customers .list:nth-child(2) 
.MuiButtonBase-root , 
.chart-top .dropdown-customers .list:nth-child(2) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-customers .list:nth-child(2) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #dc143c;
}

.chart-top .dropdown-customers .list:nth-child(3) 
.MuiButtonBase-root , 
.chart-top .dropdown-customers .list:nth-child(3) 
.MuiButtonBase-root .Mui-checked, 
.chart-top .dropdown-customers .list:nth-child(3) .MuiButtonBase-root .MuiCheckbox-indeterminate {
  color: #ff1493;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  font-size: 10px !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group {}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-marker {
  width: 8px;
  height: 8px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-text {
  font-size: 10px !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-y-group {
  padding: 2px 0;
}

.main-checkbox, .dropdown-checkbox{
  margin-top: -20px;
}


.select {
  width: 200px;
  height: 35px;
}
.main-modal .modal-content .modal-body .form .form-group .btn-style-one {
  width: 100%;
}
.main-modal .modal-content .modal-body .form .form-group .btn-group, .submit-group{
  margin-top: 20px;
}
.main-modal .modal-content .modal-body .form .form-group .btn-group,
.add-voting-page .form .form-group .btn-group{
  background-color: #f5f5f5;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.main-modal .modal-content .modal-body .form .form-group .btn-group .btn-style-one, 
.main-modal .modal-content .modal-body .form .form-group .btn-group .btn-style-two,
.add-voting-page .form .form-group .btn-group .btn-style-one,
.add-voting-page .form .form-group .btn-group .btn-style-two{
  width: 45%;
}

.main-modal .modal-content .modal-body .form .form-group .btn-group .plus-btn,
.add-voting-page .form .form-group .btn-group .plus-btn{
  width: 10%;
  text-align: center;
  border-left: 1px solid #00000070;
}
.user-choice-table table thead tr, .user-choice-table table thead tr th, .user-choice-table table tbody tr, .user-choice-table table tbody th, .user-choice-table table tbody td {
    width: 50%;
}
@media (max-width: 1366px) {
  .chart-top {
      flex-wrap: wrap;
  }
  .chart-top h1.heading{
    width: 100%;
  }
}

@media (max-width: 1166px) {
  .main-checkbox, .dropdown-checkbox {
      margin-top: 0;
  }
}