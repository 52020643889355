.main-container-head .btn-group {
    position: relative;
}

.main-container-head .btn-group .btn-style-two {
    margin-right: 15px;
    border-radius: 24px;
    background: var(--f-5-f-5-f-5, #F5F5F5);
    padding: 6px 20px;
    font-size: 14px;
}

.btn-style-one .icon {
    margin-right: 7px;
}

.btn-style-one .icon img {
    width: 19px;
}
.MuiTable-root tr td .succes-btn {
    border-radius: 24px;
    background: #07942F;
    padding: 7px 15px;
    color: #fff;
    font-size: 13px;
}

.MuiTable-root tr td .inactive-btn{
    border-radius: 24px;
    background: #FFB803;
    padding: 7px 15px;
    color: #fff;
    font-size: 13px;
}