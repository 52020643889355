.add-voting-page .form .form-group .btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-voting-page .form .form-group .voting-item {
  position: relative;
  margin-bottom: 30px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
}

.add-voting-page .form .form-group .voting-item .img-box {
  width: 100%;
  height: 200px;
}
.add-voting-page .form .form-group .voting-item .img-box img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.add-voting-page .form .form-group .voting-item .text-box {
  padding: 16px 0 7px;
}

.add-voting-page .form .form-group .voting-item .text-box h3 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}

.add-voting-page .form .form-group .voting-item .text-box p {
  font-size: 14px;
}